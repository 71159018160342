import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppFirebaseModule } from './app-firebase.module';
import { VersionCheckServiceModule } from '@msg91/service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { StoreModule } from '@ngrx/store';
import { EffectModule, reducers } from './ngrx';
import { extModules } from './build-specifics';
import { loginsReducer } from './login/ngrx/store/login.state';
import { LogInEffectsModule } from './login/ngrx/effects/login-effects.module';
import { ProxyBaseUrls } from '@msg91/models/root-models';
import { clearStateMetaReducer } from './ngrx/store/app.state';
import { ServicesAdminEmailEmailModule } from '@msg91/services/admin/email/email';
import { ServicesAdminRootModule } from '@msg91/services/admin/root';
import { ServicesHttpWrapperModule } from '@msg91/services/httpWrapper';
import { ServicesAdminAuthModule } from '@msg91/services/admin/auth';
import {
    AdminErrorInterceptor,
    ServicesAdminErrorInterceptorModule,
} from '@msg91/services/interceptor/adminErrorInterceptor';
import { UiPrimeNgToastModule } from '@msg91/ui/prime-ng-toast';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDialogModule } from '@angular/material/dialog';
import { ENVIRONMENT_TOKEN } from '@msg91/constant';

const envModule = environment.production ? [] : extModules;

@NgModule({
    declarations: [AppComponent],
    exports: [BrowserModule, BrowserAnimationsModule],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AppFirebaseModule,
        StoreModule.forRoot(reducers, { metaReducers: [clearStateMetaReducer] }),
        ...envModule,
        EffectModule,
        StoreModule.forFeature('auth', loginsReducer),
        LogInEffectsModule,
        VersionCheckServiceModule,
        ServicesAdminEmailEmailModule,
        ServicesAdminRootModule,
        ServicesHttpWrapperModule,
        ServicesAdminAuthModule,
        ServicesAdminErrorInterceptorModule,
        UiPrimeNgToastModule,
        ScrollingModule,
        MatDialogModule,
    ],
    providers: [
        { provide: ProxyBaseUrls.Env, useValue: environment.env },
        { provide: ProxyBaseUrls.ProxyURL, useValue: null },
        { provide: ProxyBaseUrls.BaseURL, useValue: environment.adminApiServer },
        { provide: ProxyBaseUrls.FirebaseConfig, useValue: environment.firebaseConfig },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline',
                floatLabel: 'auto',
            },
        },
        { provide: ProxyBaseUrls.EmailProxy, useValue: environment.emailServer + '' + environment.emailProxy },
        { provide: ProxyBaseUrls.HelloProxy, useValue: environment.helloServer },
        { provide: ProxyBaseUrls.RcsProxy, useValue: environment.rcsServer },
        { provide: ProxyBaseUrls.MsgProxy, useValue: environment.msgServer },
        {
            provide: ProxyBaseUrls.IToken,
            useValue: {
                token: null,
                companyId: null,
            },
        },
        {
            provide: ProxyBaseUrls.AdminServer,
            useValue: environment.adminServer,
        },
        {
            provide: ProxyBaseUrls.WhatsAppProxy,
            useValue: environment.whatsAppServer,
        },
        { provide: ProxyBaseUrls.VoiceBaseURL, useValue: environment.voiceServer + '' + environment.voiceProxy },
        { provide: ProxyBaseUrls.EmailServerURL, useValue: environment.emailServer },
        {
            provide: ProxyBaseUrls.SubscriptionProxy,
            useValue: environment.subscriptionProxy + '' + environment.emailProxy,
        },
        { provide: ProxyBaseUrls.ReportsUrl, useValue: environment.reportsUrl },
        { provide: ProxyBaseUrls.NumbersProxy, useValue: environment.numbersProxy },
        { provide: ProxyBaseUrls.InvisibleOTPProxy, useValue: environment.invisibleOTPProxy },
        { provide: ProxyBaseUrls.AdminProxy, useValue: environment.adminProxy },
        { provide: HTTP_INTERCEPTORS, useClass: AdminErrorInterceptor, multi: true },
        { provide: ProxyBaseUrls.CampaignProxy, useValue: environment.campaignServer },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                autoFocus: false,
                hasBackdrop: true,
                disableClose: true,
                restoreFocus: false,
            },
        },
        { provide: ENVIRONMENT_TOKEN, useValue: environment },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {}
