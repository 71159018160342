import { IGetClientResModule } from '@msg91/models/root-models';
import { Action, createReducer, on } from '@ngrx/store';
import { rootActions } from '../../actions';

export interface IRootState {
    errors: string[];
    headerTitle: string;
    clients: IGetClientResModule[];

    clientsInProcess: boolean;
    clientsSuccess: boolean;

    selectedClient: IGetClientResModule;
    showClientList: boolean;

    sideBarToggleStatus: boolean;

    inboundEmailLogPermission: boolean;
    token: string;
}

export const initialState: IRootState = {
    errors: null,

    headerTitle: null,

    clients: null,
    clientsInProcess: false,
    clientsSuccess: false,

    selectedClient: null,
    showClientList: false,

    sideBarToggleStatus: false,

    inboundEmailLogPermission: false,
    token: null,
};

export function rootReducer(state: IRootState, action: Action) {
    return _rootReducer(state, action);
}

const _rootReducer = createReducer(
    initialState,
    on(rootActions.headerTitleAction, (state, { title }) => {
        return {
            ...state,
            headerTitle: title,
        };
    }),

    on(rootActions.getClientAction, (state) => {
        return {
            ...state,
            clients: [],
            clientsInProcess: true,
            clientsSuccess: false,
            errors: [],
        };
    }),
    on(rootActions.getClientActionComplete, (state, { response }) => {
        return {
            ...state,
            clients: response.data,
            clientsInProcess: false,
            clientsSuccess: true,
            errors: [],
        };
    }),
    on(rootActions.getClientActionError, (state, { errors }) => {
        return {
            ...state,
            clients: null,
            clientsInProcess: false,
            clientsSuccess: false,
            errors: errors,
        };
    }),

    on(rootActions.setSelectedClient, (state, { response }) => {
        return {
            ...state,
            selectedClient: response,
        };
    }),

    on(rootActions.setShowClientList, (state, { response }) => {
        return {
            ...state,
            showClientList: response,
        };
    }),

    on(rootActions.setSideBarToggleStatus, (state, { status }) => {
        return {
            ...state,
            sideBarToggleStatus: status,
        };
    }),

    on(rootActions.getUserInboundEmailPermissionSuccess, (state, action) => {
        return {
            ...state,
            inboundEmailLogPermission: action?.payload.includes('can_view_inbound_email_log'),
        };
    }),

    on(rootActions.setFCMToken, (state, { token }) => {
        return {
            ...state,
            token,
        };
    })
);
