import { BaseResponse, IGetClientResModule } from '@msg91/models/root-models';
import { createAction, props } from '@ngrx/store';

export const headerTitleAction = createAction('[set header name] Header Title Action ', props<{ title: string }>());

export const getClientAction = createAction('[Get Client] Get Client', props<{ request: any }>());
export const getClientActionComplete = createAction(
    '[Get Client] Get Client Complete',
    props<{ response: BaseResponse<IGetClientResModule[], any> }>()
);
export const getClientActionError = createAction('[Get Client] Get Client Error', props<{ errors: string[] }>());

export const setSelectedClient = createAction(
    '[Get Client] Set Selected Client',
    props<{ response: IGetClientResModule }>()
);
export const setShowClientList = createAction('[Get Client] Set Show Client List', props<{ response: boolean }>());
export const setSideBarToggleStatus = createAction('Set Side Bar Toggle Status', props<{ status: boolean }>());

export const getUserInboundEmailPermission = createAction('[Email] Get User Inbound Email Permission');
export const getUserInboundEmailPermissionSuccess = createAction(
    'Get User Inbound Email Permission Success',
    props<{ payload: string[] }>()
);

export const setFCMToken = createAction('[Root] Set FCM Token', props<{ token: string }>());
