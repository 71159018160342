import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { RootEffects } from './root';
import { ServicesAdminRootModule } from '@msg91/services/admin/root';
import { ServicesAdminEmailOperationPermissionModule } from '@msg91/services/admin/email/operation-permission';

@NgModule({
    imports: [
        CommonModule,
        EffectsModule.forRoot([RootEffects]),
        ServicesAdminRootModule,
        ServicesAdminEmailOperationPermissionModule,
    ],
    exports: [EffectsModule],
})
export class EffectModule {
    public static forRoot(): ModuleWithProviders<EffectModule> {
        return {
            ngModule: EffectModule,
            providers: [],
        };
    }
}
