export const GOOGLE_LANGUAGE_TRANSLATE = {
    // 'af': 'Afrikaans',
    // 'sq': 'Albanian',
    // 'ar': 'Arabic',
    // 'az': 'Azerbaijani',
    // 'eu': 'Basque',
    // 'bn': 'Bengali',
    // 'be': 'Belarusian',
    // 'bg': 'Bulgarian',
    // 'ca': 'Catalan',
    // 'zh-CN': 'Chinese Simplified',
    // 'zh-TW': 'Chinese Traditional',
    // 'hr': 'Croatian',
    // 'cs': 'Czech',
    // 'da': 'Danish',
    // 'nl': 'Dutch',
    // 'en': 'English',
    // 'eo': 'Esperanto',
    // 'et': 'Estonian',
    // 'tl': 'Filipino',
    // 'fi': 'Finnish',
    // 'fr': 'French',
    // 'gl': 'Galician',
    // 'ka': 'Georgian',
    // 'de': 'German',
    // 'el': 'Greek',
    // 'gu': 'Gujarati',
    // 'ht': 'Haitian Creole',
    // 'iw': 'Hebrew',
    // 'hi': 'Hindi',
    // 'hu': 'Hungarian',
    // 'is': 'Icelandic',
    // 'id': 'Indonesian',
    // 'ga': 'Irish',
    // 'it': 'Italian',
    // 'ja': 'Japanese',
    // 'kn': 'Kannada',
    // 'ko': 'Korean',
    // 'la': 'Latin',
    // 'lv': 'Latvian',
    // 'lt': 'Lithuanian',
    // 'mk': 'Macedonian',
    // 'ms': 'Malay',
    // 'mt': 'Maltese',
    // 'no': 'Norwegian',
    // 'fa': 'Persian',
    // 'pl': 'Polish',
    // 'pt': 'Portuguese',
    // 'ro': 'Romanian',
    // 'ru': 'Russian',
    // 'sr': 'Serbian',
    // 'sk': 'Slovak ',
    // 'sl': 'Slovenian',
    // 'es': 'Spanish',
    // 'sw': 'Swahili',
    // 'sv': 'Swedish',
    // 'ta': 'Tamil',
    // 'te': 'Telugu ',
    // 'th': 'Thai',
    // 'tr': 'Turkish',
    // 'uk': 'Ukrainian',
    // 'ur': 'Urdu',
    // 'vi': 'Vietnamese',
    // 'cy': 'Welsh',
    // 'yi': 'Yiddish',

    'en': 'ENGLISH',
    'am': 'AMHARIC',
    'ar': 'ARABIC',
    'bn': 'BENGALI',
    'el': 'GREEK',
    'gu': 'GUJARATI',
    'hi': 'HINDI',
    'kn': 'KANNADA',
    'ml': 'MALAYALAM',
    'mr': 'MARATHI',
    'ne': 'NEPALI',
    'or': 'ODIA',
    'fa': 'PERSIAN',
    'pa': 'PUNJABI',
    'ru': 'RUSSIAN',
    'sa': 'SANSKRIT',
    'si': 'SINHALESE',
    'sr': 'SERBIAN',
    'ta': 'TAMIL',
    'te': 'TELUGU',
    'ti': 'TIGRINYA',
    'ur': 'URDU',
};
