<p-toast position="bottom-left" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="d-flex flex-grow-1">
            <p
                class="flex-grow-1 mat-body-2 fw-bolder m-0 d-flex justify-content-between"
                style="line-height: 28px; word-break: break-word; max-width: 350px"
            >
                {{ message.detail }}
            </p>
            <button
                mat-icon-button
                disableRipple
                class="mat-icon-default mat-icon-24"
                style="margin-right: -21px; margin-top: 1px"
            >
                <mat-icon> close </mat-icon>
            </button>
        </div>
    </ng-template>
</p-toast>

<p-toast position="bottom-left" key="customAction" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="d-flex flex-grow-1">
            <p
                class="flex-grow-1 mat-body-2 fw-bolder m-0 d-flex justify-content-between"
                style="line-height: 28px; word-break: break-word"
            >
                {{ message.detail?.message }}
                <span *ngIf="(undoTimer$ | async) > 0" class="mr-2 text-danger">
                    undo in {{ undoTimer$ | async }}
                </span>
            </p>
            <button
                mat-icon-button
                disableRipple
                class="mat-icon-default mat-icon-24"
                [matTooltip]="message?.detail?.buttonContent?.tooltip ?? ''"
                matTooltipPosition="above"
                *ngIf="!message?.detail?.buttonContent?.isUndo || (undoTimer$ | async) > 0"
                (click)="onCustomActionClick(message)"
            >
                <mat-icon> {{ message?.detail?.buttonContent?.icon ?? 'close' }} </mat-icon>
            </button>
        </div>
    </ng-template>
</p-toast>
