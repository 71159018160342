<h1
    mat-dialog-title
    class="mat-subheading-2 text-dark"
    [innerHTML]="title"
    *skeleton="showLoader; height: '20px'; className: ['w-25', 'rounded-4', 'mb-3']"
></h1>
<mat-dialog-content class="w-100">
    <p
        class="text-dark mat-body-2 my-0"
        [innerHTML]="confirmationMessage"
        *skeleton="showLoader; height: '20px'; className: ['w-100', 'rounded-4', 'mb-2']; repeat: 2"
    ></p>
</mat-dialog-content>
<div cdkTrapFocus mat-dialog-actions class="d-flex gap-2 align-items-center" [ngClass]="footerButtonPosition">
    <button
        *ngIf="showCancelButton"
        cdkFocusRegionStart
        type="reset"
        [ngClass]="cancelButtonClass"
        mat-dialog-close
        mat-button
        [color]="cancelButtonColor"
        (click)="closeDialog('no')"
    >
        {{ cancelButtonText }}
    </button>
    <button
        *ngIf="showConfirmButton"
        type="button"
        mat-flat-button
        mat-dialog-close
        cdkFocusRegionEnd
        cdkFocusInitial
        [ngClass]="confirmButtonClass"
        [color]="confirmButtonColor"
        (click)="closeDialog('yes')"
    >
        {{ confirmButtonText }}
    </button>
</div>
