import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { IToken, ProxyBaseUrls } from '@msg91/models/root-models';
import { VersionCheckService } from '@msg91/service';
import { select, Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { BaseComponent } from '@msg91/ui/base-component';
import { selectLogInDataSuccess, selectLogOutSuccess } from './login/ngrx/selector/login.selector';
import { ILogInFeatureStateWithRootState } from './login/ngrx/store/login.state';
import { IAppState, selectAccessToken, selectSelectedClientUserPid } from './ngrx';
import { rootActions } from './ngrx/actions';
import * as logInActions from './login/ngrx/actions/login.action';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as dayjs from 'dayjs';
import { AuthService } from '@msg91/services/admin/auth';

@Component({
    selector: 'msg91-root',
    templateUrl: './app.component.html',
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
    public title = 'msg-admin';
    public logoutActionComplete$: Observable<boolean>;
    public selectAccessToken$: Observable<string>;
    public companyId$: Observable<string>;

    /** True, if new build is deployed */
    private newVersionAvailableForWebApp: boolean = false;
    private timer: any;

    constructor(
        private _store: Store<ILogInFeatureStateWithRootState>,
        private router: Router,
        private actRoute: ActivatedRoute,
        private store: Store<IAppState>,
        @Inject(ProxyBaseUrls.IToken) private token: IToken,
        private versionCheckService: VersionCheckService,
        private afAuth: AngularFireAuth,
        private authService: AuthService
    ) {
        super();

        this._store.dispatch(logInActions.getUserAction());
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.actRoute),
                map((route) => {
                    while (route.firstChild) route = route.firstChild;
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.data),
                distinctUntilChanged(isEqual),
                takeUntil(this.destroy$)
            )
            .subscribe((event) => {
                this.store.dispatch(rootActions.headerTitleAction({ title: event['title'] }));
            });
        this.router.events
            .pipe(
                filter((event: any) => event instanceof NavigationStart),
                takeUntil(this.destroy$)
            )
            .subscribe((res) => {
                if (this.newVersionAvailableForWebApp) {
                    window.location.href = `${environment.adminServer?.substring(0, environment.adminServer.length)}${
                        res.url
                    }`;
                }
            });
        this.logoutActionComplete$ = this._store.pipe(
            select(selectLogOutSuccess),
            distinctUntilChanged(isEqual),
            takeUntil(this.destroy$)
        );
        this.selectAccessToken$ = this._store.pipe(
            select(selectAccessToken),
            distinctUntilChanged(isEqual),
            takeUntil(this.destroy$)
        );
        this.companyId$ = this.store.pipe(
            select(selectSelectedClientUserPid),
            distinctUntilChanged(isEqual),
            takeUntil(this.destroy$)
        );
    }

    public ngOnInit(): void {
        this.logoutActionComplete$.subscribe((res) => {
            if (res) {
                this.router.navigate(['/login']);
            }
        });

        // this._store
        //     .pipe(select(selectLogInDataSuccess), distinctUntilChanged(isEqual), takeUntil(this.destroy$))
        //     .subscribe((res) => {
        //         if (res) {
        //             this.checkAuthTime();
        //         }
        //     });

        this.companyId$.subscribe((companyId) => {
            this.token.companyId = companyId;
        });

        this.selectAccessToken$.subscribe((token) => {
            if (!token) return;
            this.token.token = token;
            this.authService.fetchActiveToken();
        });

        if (environment.env !== 'local') {
            this.versionCheckService.initVersionCheck(environment.adminServer + '/version.json');

            this.versionCheckService.onVersionChange$.pipe(takeUntil(this.destroy$)).subscribe((isChanged: boolean) => {
                if (isChanged) {
                    this.newVersionAvailableForWebApp = isChanged;
                }
            });
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    private checkAuthTime(): void {
        this.afAuth.authState
            .pipe(
                filter((p) => Boolean(p)),
                switchMap((p) => p?.getIdTokenResult())
            )
            .subscribe((res) => {
                const issuedDate = new Date(res.authTime);
                const currentDate = new Date();
                const hoursBetweenDates = Math.abs(issuedDate.getTime() - currentDate.getTime()) / (60 * 60 * 1000);
                const expirationTime = dayjs(new Date(res.expirationTime));
                const currentTime = dayjs(new Date());
                const timeoutValue = expirationTime.diff(currentTime);
                if (hoursBetweenDates < 168) {
                    // 168 hrs to maintain session for 7 days
                    this.authService
                        .getToken(timeoutValue <= 0)
                        .pipe(take(1))
                        .subscribe((token) => {
                            this.store.dispatch(rootActions.setFCMToken({ token }));
                        });
                    // onAuthStateChanged is not working with observable, need to find some other method
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        this.checkAuthTime();
                    }, timeoutValue + 1);
                } else {
                    this._store.dispatch(logInActions.logoutAction());
                }
            });
    }
}
