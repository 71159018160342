const measurementId =
    process.env.MSG91_ADMIN_FIREBASE_CONFIG_MEASUREMENT_ID &&
    process.env.MSG91_ADMIN_FIREBASE_CONFIG_MEASUREMENT_ID !== 'null'
        ? process.env.MSG91_ADMIN_FIREBASE_CONFIG_MEASUREMENT_ID
        : null;

export const envVariables = {
    firebaseConfig: {
        apiKey: process.env.MSG91_ADMIN_FIREBASE_CONFIG_API_KEY,
        authDomain: process.env.MSG91_ADMIN_FIREBASE_CONFIG_AUTH_DOMAIN,
        projectId: process.env.MSG91_ADMIN_FIREBASE_CONFIG_PROJECT_ID,
        storageBucket: process.env.MSG91_ADMIN_FIREBASE_CONFIG_STORAGE_BUCKET,
        messagingSenderId: process.env.MSG91_ADMIN_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
        appId: process.env.MSG91_ADMIN_FIREBASE_CONFIG_APP_ID,
        ...(measurementId && { measurementId }),
    },
    adminApiServer: process.env.MSG91_ADMIN_API_SERVER,
    emailServer: process.env.MSG91_ADMIN_EMAIL_SERVER,
    emailProxy: process.env.MSG91_ADMIN_EMAIL_PROXY,
    msgServer: process.env.MSG91_ADMIN_MSG_SERVER,
    helloServer: process.env.MSG91_ADMIN_HELLO_SERVER,
    rcsServer: process.env.MSG91_ADMIN_RCS_SERVER,
    adminServer: process.env.MSG91_ADMIN_SERVER,
    whatsAppServer: process.env.MSG91_ADMIN_WHATSAPP_SERVER,
    voiceServer: process.env.MSG91_ADMIN_VOICE_SERVER,
    campaignServer: process.env.MSG91_ADMIN_CAMPAIGN_SERVER,
    voiceProxy: process.env.MSG91_ADMIN_VOICE_PROXY,
    subscriptionProxy: process.env.MSG91_ADMIN_SUBSCRIPTION_PROXY,
    reportsUrl: process.env.MSG91_ADMIN_REPORTS_URL,
    numbersProxy: process.env.MSG91_ADMIN_NUMBERS_PROXY,
    adminProxy: process.env.MSG91_ADMIN_ADMIN_PROXY,
    invisibleOTPProxy: process.env.MSG91_ADMIN_INVISIBLE_OTP_PROXY,
};
