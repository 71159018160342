import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RootService } from '@msg91/services/admin/root';
import { AdminEmailOperationPermissionService } from '@msg91/services/admin/email/operation-permission';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BaseResponse, errorResolver, IGetClientResModule } from '@msg91/models/root-models';
import { EMPTY, of } from 'rxjs';
import { rootActions } from 'src/app/ngrx/actions';

@Injectable()
export class RootEffects {
    constructor(
        private actions$: Actions,
        private service: RootService,
        private adminEmailService: AdminEmailOperationPermissionService
    ) {}

    getClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(rootActions.getClientAction),
            switchMap((p) => {
                return this.service.getClient(p.request).pipe(
                    map((res: BaseResponse<IGetClientResModule[], null>) => {
                        if (res.hasError) {
                            return rootActions.getClientActionError({ errors: errorResolver(res.errors) });
                        }
                        return rootActions.getClientActionComplete({ response: res });
                    }),
                    catchError((err) => {
                        return of(rootActions.getClientActionError({ errors: errorResolver(err.errors) }));
                    })
                );
            })
        )
    );

    // Get LoggedIn User Email Permissions
    getLoggedInUserPermissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(rootActions.getUserInboundEmailPermission),
            switchMap((payload) => {
                return this.adminEmailService.getLoggedInUser();
            }),
            map((user) => {
                return {
                    type: rootActions.getUserInboundEmailPermissionSuccess.type,
                    payload: user?.data?.meta?.permissions,
                };
            }),
            catchError(() => EMPTY)
        )
    );
}
